<template>
  <v-container class="py-8">
    <v-row no-gutters class="justify-center">
      <!-- <v-col cols="12" class="col-sm-4">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height indigo font-weight-bold" dark outlined>
          12 Products
        </v-card>
      </v-col> -->
      <v-col cols="12" class="col-sm-8">
        <div class="d-flex flex-column align-center justify-center px-8 pt-4 pb-8 white">
          <v-card flat class="text-center pa-4 fill-width transparent font-weight-bold grey--text">
            - MENU -
          </v-card>
          <v-row class="d-flex fill-width align-center justify-center pt-4">
            <v-col cols="12" class="col-sm-4">
              <v-card v-if="$role(['manager', 'cashier'], $store.state.user.role)" @click.prevent="$router.push('/transaction')" outlined class="pa-4 text-center indigo caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-cash-cashier
                </v-icon>
                Transaction
              </v-card>
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-card v-if="$role(['manager', 'warehouse'], $store.state.user.role)" @click.prevent="$router.push('/inventory')" outlined class="pa-4 text-center indigo caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-checkbox-blank-badge-outline
                </v-icon>
                Product / Inventory
              </v-card>
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-card v-if="$role(['manager'], $store.state.user.role)" @click.prevent="$router.push('/features/voucher-point')" outlined class="pa-4 text-center indigo caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-arrange-send-to-back
                </v-icon>
                Features
              </v-card>
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-card v-if="$role(['manager'], $store.state.user.role)" @click.prevent="$router.push('/customer')" outlined class="pa-4 text-center indigo caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-account-group
                </v-icon>
                Customer
              </v-card>
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-card v-if="$role(['manager'], $store.state.user.role)" @click.prevent="$router.push('/account-manager')" outlined class="pa-4 text-center indigo caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-account-cog
                </v-icon>
                Account Manager
              </v-card>
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-card v-if="$role(['manager'], $store.state.user.role)" @click.prevent="$router.push('/store-manager')" outlined class="pa-4 text-center indigo caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-store-cog
                </v-icon>
                Store Manager
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home'
}
</script>
