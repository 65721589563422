import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/Inventory.vue')
  },
  {
    path: '/transaction',
    name: 'Transaction',
    component: () => import(/* webpackChunkName: "transaction" */ '../views/Transaction.vue')
  },
  {
    path: '/customer',
    name: 'Customer',
    component: () => import(/* webpackChunkName: "customer" */ '../views/Customer.vue')
  },
  {
    path: '/account-manager',
    name: 'AccountManager',
    component: () => import(/* webpackChunkName: "account-manager" */ '../views/AccountManager.vue')
  },
  {
    path: '/store-manager',
    name: 'StoreManager',
    component: () => import(/* webpackChunkName: "store-manager" */ '../views/StoreManager.vue')
  },
  {
    path: '/features/:page',
    name: 'Banner',
    component: () => import(/* webpackChunkName: "feature" */ '../views/features/_index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
