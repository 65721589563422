import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:8021' : 'https://lamoringa-api.lumira.live'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common.role = 'true'
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + (localStorage.getItem('t') || '')
  return config
}, function (error) {
  return Promise.reject(error)
})

Vue.use(VueAxios, axios)
